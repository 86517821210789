






































import {
  positiveNumber,
  validNumber,
  required,
  integer
} from '@/helpers/validation';

import isEqual from 'lodash/isEqual';
import {
  getCopystakeIntegration,
  updateCopystakeIntegration
} from '@/api/CopyStake';
import * as vr from '@/helpers/validation';

type TSettingsValues = {
  apiKey: string;
  ttl: string | number;
  callbackUrl: string;
};
type TSettingsData = {
  values: TSettingsValues;
  savedValues: TSettingsValues;
  formValid: boolean;
  isSubmitting: boolean;
  isLoading: boolean;
};

type TFormField = {
  label: string;
  tooltip: string;
  key: string;
  classes: Record<string, boolean>;
};

type TValidator = (v: string) => boolean | string;

export default {
  name: 'OnboardingCopystakeSettings',
  data(): TSettingsData {
    return {
      values: {
        apiKey: null,
        ttl: null,
        callbackUrl: null
      },
      savedValues: {
        apiKey: null,
        ttl: null,
        callbackUrl: null
      },
      formValid: false,
      isSubmitting: false,
      isLoading: false
    };
  },
  computed: {
    isReadonly(): boolean {
      return !this.canUpdate || this.isLoading || this.isSubmitting;
    },
    canUpdate(): boolean {
      return this.$role.can.update('streamers');
    },
    submitDisabled(): boolean {
      return !this.isUpdated || !this.formValid || this.isLoading;
    },
    validationRules(): {
      apiKey: TValidator[];
      ttl: TValidator[];
      callbackUrl: TValidator[];
    } {
      return {
        apiKey: [(v) => required(v, 'API Key')],
        ttl: [
          (v) => required(v, 'TTL Session'),
          integer,
          validNumber,
          positiveNumber
        ],
        callbackUrl: [
          (v) => required(v, 'Callback URL'),
          vr.url
        ]
      };
    },
    isUpdated(): boolean {
      return !isEqual(this.savedValues, this.values);
    },
    fields(): TFormField[] {
      return [
        {
          label: 'API Key',
          tooltip: 'API Key for integration',
          key: 'apiKey',
          classes: { 'no-pointer-events': !this.canUpdate }
        },
        {
          label: 'TTL Session',
          tooltip: 'TTL for session synchronization in a minutes',
          key: 'ttl',
          classes: { 'no-pointer-events': !this.canUpdate }
        },
        {
          label: 'Callback URL',
          tooltip: 'Callback URL for integration',
          key: 'callbackUrl',
          classes: { 'no-pointer-events': !this.canUpdate }
        }
      ];
    }
  },
  mounted(): void {
    this.getSavedSettings();
  },
  methods: {
    updateSettings(): void {
      if (this.isSubmitting) return;

      this.isSubmitting = true;

      updateCopystakeIntegration(this.values)
        .then(() => {
          this.savedValues = Object.freeze({ ...this.values });

          this.$toast.success('CopyStake settings updated.');
        })
        .catch(() => this.$toast.error('CopyStake settings update failed.'))
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    getSavedSettings(): void {
      this.isLoading = true;

      getCopystakeIntegration()
        .then((data) => {
          this.values.apiKey = data.apiKey;
          this.values.ttl = data.ttl;
          this.values.callbackUrl = data.callbackUrl;

          this.savedValues = Object.freeze({ ...data });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
